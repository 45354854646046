/* Import the Inter font family */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

html {
	width: 100vw;
	height: 100vh;
}

body,
#root {
	margin: 0 !important;
	width: 100%;
	height: 100%;
	background-color: #f2f2f2;
}

.mantine-nsdj {
	margin: 0 !important;
}
